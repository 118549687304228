exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cv-js": () => import("./../../../src/pages/cv.js" /* webpackChunkName: "component---src-pages-cv-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-showcase-algorithms-insertion-sort-js": () => import("./../../../src/pages/showcase/algorithms/insertion-sort.js" /* webpackChunkName: "component---src-pages-showcase-algorithms-insertion-sort-js" */),
  "component---src-pages-showcase-astronomy-equation-of-time-js": () => import("./../../../src/pages/showcase/astronomy/equation-of-time.js" /* webpackChunkName: "component---src-pages-showcase-astronomy-equation-of-time-js" */),
  "component---src-pages-showcase-ballistic-sim-1-js": () => import("./../../../src/pages/showcase/ballistic/sim-1.js" /* webpackChunkName: "component---src-pages-showcase-ballistic-sim-1-js" */),
  "component---src-pages-tag-js": () => import("./../../../src/pages/tag.js" /* webpackChunkName: "component---src-pages-tag-js" */),
  "component---src-templates-blog-js-content-file-path-src-content-blog-first-year-of-university-index-mdx": () => import("./../../../src/templates/blog.js?__contentFilePath=/opt/build/repo/src/content/blog/first-year-of-university/index.mdx" /* webpackChunkName: "component---src-templates-blog-js-content-file-path-src-content-blog-first-year-of-university-index-mdx" */),
  "component---src-templates-blog-js-content-file-path-src-content-blog-getting-into-film-slr-photography-index-mdx": () => import("./../../../src/templates/blog.js?__contentFilePath=/opt/build/repo/src/content/blog/getting-into-film-slr-photography/index.mdx" /* webpackChunkName: "component---src-templates-blog-js-content-file-path-src-content-blog-getting-into-film-slr-photography-index-mdx" */),
  "component---src-templates-blog-js-content-file-path-src-content-blog-testing-helios-44-2-lens-versions-index-mdx": () => import("./../../../src/templates/blog.js?__contentFilePath=/opt/build/repo/src/content/blog/testing-helios-44-2-lens-versions/index.mdx" /* webpackChunkName: "component---src-templates-blog-js-content-file-path-src-content-blog-testing-helios-44-2-lens-versions-index-mdx" */),
  "component---src-templates-blog-js-content-file-path-src-content-blog-trip-around-westminster-south-bank-and-southwark-index-mdx": () => import("./../../../src/templates/blog.js?__contentFilePath=/opt/build/repo/src/content/blog/trip-around-westminster-south-bank-and-southwark/index.mdx" /* webpackChunkName: "component---src-templates-blog-js-content-file-path-src-content-blog-trip-around-westminster-south-bank-and-southwark-index-mdx" */),
  "component---src-templates-blog-js-content-file-path-src-content-blog-university-modules-index-mdx": () => import("./../../../src/templates/blog.js?__contentFilePath=/opt/build/repo/src/content/blog/university-modules/index.mdx" /* webpackChunkName: "component---src-templates-blog-js-content-file-path-src-content-blog-university-modules-index-mdx" */),
  "component---src-templates-blog-js-content-file-path-src-content-blog-using-custom-email-addresses-to-filter-incoming-emails-index-mdx": () => import("./../../../src/templates/blog.js?__contentFilePath=/opt/build/repo/src/content/blog/using-custom-email-addresses-to-filter-incoming-emails/index.mdx" /* webpackChunkName: "component---src-templates-blog-js-content-file-path-src-content-blog-using-custom-email-addresses-to-filter-incoming-emails-index-mdx" */),
  "component---src-templates-blog-js-content-file-path-src-content-blog-using-latex-with-vs-code-index-mdx": () => import("./../../../src/templates/blog.js?__contentFilePath=/opt/build/repo/src/content/blog/using-latex-with-vs-code/index.mdx" /* webpackChunkName: "component---src-templates-blog-js-content-file-path-src-content-blog-using-latex-with-vs-code-index-mdx" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-project-js-content-file-path-src-content-project-analog-music-synthesizer-simulation-index-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/opt/build/repo/src/content/project/analog-music-synthesizer-simulation/index.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-src-content-project-analog-music-synthesizer-simulation-index-mdx" */),
  "component---src-templates-project-js-content-file-path-src-content-project-autonomous-mars-rover-index-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/opt/build/repo/src/content/project/autonomous-mars-rover/index.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-src-content-project-autonomous-mars-rover-index-mdx" */),
  "component---src-templates-project-js-content-file-path-src-content-project-dsp-voice-synthesizer-index-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/opt/build/repo/src/content/project/dsp-voice-synthesizer/index.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-src-content-project-dsp-voice-synthesizer-index-mdx" */),
  "component---src-templates-project-js-content-file-path-src-content-project-fpga-coursework-index-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/opt/build/repo/src/content/project/fpga-coursework/index.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-src-content-project-fpga-coursework-index-mdx" */),
  "component---src-templates-project-js-content-file-path-src-content-project-how-i-made-this-personal-website-index-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/opt/build/repo/src/content/project/how-i-made-this-personal-website/index.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-src-content-project-how-i-made-this-personal-website-index-mdx" */),
  "component---src-templates-project-js-content-file-path-src-content-project-impedance-measurement-meter-index-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/opt/build/repo/src/content/project/impedance-measurement-meter/index.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-src-content-project-impedance-measurement-meter-index-mdx" */),
  "component---src-templates-project-js-content-file-path-src-content-project-model-rocket-flight-computer-index-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/opt/build/repo/src/content/project/model-rocket-flight-computer/index.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-src-content-project-model-rocket-flight-computer-index-mdx" */),
  "component---src-templates-project-list-js": () => import("./../../../src/templates/project-list.js" /* webpackChunkName: "component---src-templates-project-list-js" */),
  "component---src-templates-tag-list-js": () => import("./../../../src/templates/tag-list.js" /* webpackChunkName: "component---src-templates-tag-list-js" */)
}

